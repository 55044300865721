<template>
  <div class="Matchcenter">
    <Header
      back="返回"
      title="匹配工具"
      index="首页"
      titleOne="智能匹配"
      beforeTitle="匹配工具"
    />
    <div class="content">
      <h1>苏州市相城区高企申报政策的匹配结果</h1>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="政策名称" width="150">
        </el-table-column>
        <el-table-column prop="type" label="客户来源类型" width="150">
        </el-table-column>
        <el-table-column prop="time" label="匹配时间"> </el-table-column>

        <el-table-column prop="progress" label="匹配进度" width="200">
          <template slot-scope="scope">
            <el-progress
              type="line"
              :stroke-width="8"
              :percentage="scope.row.progress"
            ></el-progress>
          </template>
        </el-table-column>

        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button
              class="edit"
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="100"
          layout="prev, pager, next, jumper"
          :total="1000"
        >
        </el-pagination>
      </div>
      <div class="foot_TJ">
        <el-button type="primary" @click="onSubmit">保存结果</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPage3: 5,
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
          state: "启用",
          progress: 40,
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
          state: "禁用",
          progress: 100,
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
          progress: 30,
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
          progress: 80,
        },
      ],
      value: "",
      input: "",
    };
  },

  methods: {},
};
</script>
<style>
.el-progress__text {
  color: #0f38ff;
  font-weight: bold;
  font-size: 14px;
}
</style>
